# Lightbox-related functions
#
# This builds on top of fancybox, providing some compatibility with the old code

namespace 'Slzr.Lightbox', (exports, top) ->
  jQuery = $ = Slzr.jQuery

  # Default fancybox options
  exports.default_fancybox_options =
    overlayColor: '#111'
    padding: 0
  #  centerOnScroll: false

  # Expose fancybox options as global variable
  top.default_fancybox_options = exports.default_fancybox_options

  previousActiveElement = null

  # Display a lightbox
  exports.open = (params={}) ->
    # Triggering element
    element = params.element
    $element = jQuery(element) if element
    # Trigger element
    event = params.event

    # If the theme defines an makeOnStartHook handler, get it and bind it to this + event
    if Slzr?.Theme?.Lightbox?.makeOnStartHook?
      themeOnStart = Slzr.Theme.Lightbox.makeOnStartHook(element, event)?.bind(element, event)

    FocusTrap = modulejs.require('slzr/modal-focus-trap')
    focusTrapped = false

    options = Slzr.jQuery.extend {}, default_fancybox_options, params,
      onStart: ->
        themeOnStart?()
        params.onStart?()

      onClosed: ->
        # Trigger lightbox closed event on the link
        $element?.trigger('slzr:lightbox-closed')

        # Disable focus trapping
        FocusTrap.untrap() if focusTrapped

        # Restore focus to the previous element, if set
        previousActiveElement?.focus()
        previousActiveElement = null

        # Trigger ajax complete event to restore the disable-with state of the opening element (if any).
        #
        # TODO: Revamp login lightbox without need for this
        $element?.trigger('ajax:complete') if $element?.data('ujs:enable-with')

        params.onClosed?()

      onComplete: ->
        post_login_action = $element?.data('post-login-action')
        post_login_action = null if post_login_action == '' or post_login_action == '#'

        # Update lightbox contents with the post login action, if specified
        if post_login_action
          # Any previous_url form elements
          $('#fancybox-content [name=previous_url]').val(post_login_action)

          # Handle encoded shibboleth URLs
          encoded_url = encodeURIComponent post_login_action
          $('#fancybox-content')[0].innerHTML = $('#fancybox-content')[0].innerHTML.replace(/(previous_url=)[^&"]*([&"])/g, '$1' + encoded_url + '$2')

        # Store the previously focused element
        previousActiveElement = document.activeElement

        # Try to focus the first visible element
        FocusTrap.focusFirstDescendant($('#fancybox-content').get(0))

        # Enable focus trapping within the lightbox, unless it's explicitly disabled via data-disable-focus-trap
        unless $element?.is('[data-disable-focus-trap]')
          FocusTrap.trap(document.getElementById('fancybox-outer'))
          focusTrapped = true

        # Trigger a lightbox opened event on the link
        $element?.trigger('slzr:lightbox-opened')

        params.onComplete?()

    jQuery.fancybox(options)

  exports.close = () ->
    jQuery.fancybox.close()

  # Opens a lightbox with the specified URL
  #
  # If URL starts with a #, will assume it is the ID of a dom element to display,
  # otherwise will do an AJAX style request
  openIbox = (url, params={}) ->
    if url.substr(0, 1) == '#'
      # Element (inline)
      type = 'inline'
      href = url
    else if url.match(/^\//) || url.match(/^https?\:/)
      # Ajax anything starting with http or a /
      href = url
      type = if url.match(/\.(png|jpg|jpeg|gif)/i) then 'image' else 'ajax'
    else
      content = url
      href = null
      type = null

    options = Slzr.jQuery.extend {}, default_fancybox_options,
      type: type
      onComplete: params.onComplete
      onClosed: params.onClosed
      onStart: params.onStart
      element: params.element
      event: params.event

    options.href = href if href?
    options.content = content if content?

    exports.open(options)

  # Compatibility exports
  top.openIbox = openIbox
  top.hideIbox = exports.close

  # Export the earlier global variables (those assigned to top), if webpack is running
  if module?.exports?
    module.exports.openIbox = top.openIbox
    module.exports.hideIbox = top.hideIbox
    module.exports.default_fancybox_options = top.default_fancybox_options

# Attach old ibox helpers
Slzr.jQuery ($) ->
  $('a[rel^="ibox"]').attr 'onclick', null
  $(document).delegate 'a[rel^="ibox"]', 'click', (evt) ->
    el = $(this)
    parts = _.without(el.attr('rel').split(/^ibox[;\#]/), '')
    url = null
    for p in parts[0].split(';')
      if p.indexOf('url=') == 0
        url = p.substr(4)

    openIbox url,
      element: this,
      event: evt

    evt.preventDefault()

    return false

  # data-action handler for showing a lightbox
  #
  # the lightbox (found with data-lightbox) should be specified
  # using a data-show-lightbox attribute on the element
  $(document).onAction 'show-lightbox', (evt) ->
    $el = $(this)
    lightbox = $el.data('show-lightbox')

    lightbox_element = $("[data-lightbox=\"#{lightbox}\"]").uniqueId()

    if lightbox_element.length == 1
      lightbox_url = "##{lightbox_element.attr('id')}"
      openIbox(lightbox_url, element: this, event: evt)
    else
      console?.error('Lightbox not found: ' + lightbox)


  # Show survey dialog, and set cookie
  # Only show the survey lightbox if the container is in dom
  if $('#x-survey-lightbox').length > 0
    SURVEY_VERSION = '2'
    unless $.cookie('seen_survey') == SURVEY_VERSION
      $.cookie 'seen_survey', SURVEY_VERSION, path: '/', expires: 45 # days
      openIbox '#x-survey-lightbox'

  # Show admin survey dialog, and set cookie
  # Only show the survey lightbox if the container is in dom
  if $('#x-admin-survey-lightbox').length > 0
    ADMIN_SURVEY_VERSION = '2'
    unless $.cookie('seen_admin_survey') == ADMIN_SURVEY_VERSION
      $.cookie 'seen_admin_survey', ADMIN_SURVEY_VERSION, path: '/', expires: 45 # days
      openIbox '#x-admin-survey-lightbox'

