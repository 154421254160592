modulejs.define 'slzr/bulletin/schedule_form/form_component',
  ['react', 'moment', 'underscore', 'slzr/react/form_row', 'slzr/react/form_input_group', 'slzr/components/date_input_row',
   'slzr/react/checkbox', 'slzr/react/form_header'],
  (React, moment, _, FormRow, FormInputGroup, DateInputRow,
   Checkbox, FormHeader) ->

    # Return a number's ordinal form
    asOrdinal = (number) ->
      suffixes = ['th', 'st', 'nd', 'rd']
      v = number % 100
      suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]

      "#{number}#{suffix}"

    # Spell out small (<= 10) positive integers, otherwise just return hte number
    numberAsWord = (number) ->
      numbers = [null, 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']
      if number > 0 && number <= 10
        numbers[number]
      else
        number

    # Pluralize the given string for count
    pluralize = (count, string, plural_string=null) ->
      if Number(count) == 1
        string
      else
        plural_string || (string + "s")

    # Component wrapping a form segment for the instance management
    #
    # This covers the date inputs as well as the instance list
    class ScheduleFormComponent extends React.Component

      constructor: (props) ->
        super(props)

        # Create initial state
        this.state =
          # Schedule type (automatic, manual)
          schedule_type: 'manual'
          # Send hour
          send_time: 0
          # Repeat type
          repeat_type: 'once'
          # Repeat interval
          interval: 1
          # Selected days of week (weekly recurrence)
          weekdays: [0, 1, 2, 3, 4, 5, 6]
          # Month repeat type (monthly recurrence)
          month_repeat_on: 'day_of_month'
          # End type for recurrence
          recurrence_end_type: 'date'
          # End date for recurrence
          recurrence_end_date: null
          recurrence_end_date_invalid: false
          # End count for recurrence
          recurrence_end_count: null
          recurrence_end_count_invalid: false
          # Current entry start date
          start_date: null
          start_date_invalid: false
          # Valid values entered, allowing addition of dates
          valid_entry: false
          # Have entries of dates
          have_dates: this.props.eventInstances.count() > 0

        # Apply initial schedule state
        this.state = _.assign this.state, @_calculateScheduleState(props.scheduleData)

      UNSAFE_componentWillReceiveProps: (nextProps) ->
        if nextProps.scheduleData != this.props.scheduleData
          @setState @_calculateScheduleState(nextProps.scheduleData), @validateState

      componentDidMount: =>
        @props.eventInstances.on 'slzr:instance:add', @_instancesChanged
        @props.eventInstances.on 'slzr:instance:change', @_instancesChanged
        @props.eventInstances.on 'slzr:instance:remove', @_instancesChanged

      componentWillUnmount: =>
        @props.eventInstances.removeListener 'slzr:instance:add', @_instancesChanged
        @props.eventInstances.removeListener 'slzr:instance:change', @_instancesChanged
        @props.eventInstances.removeListener 'slzr:instance:remove', @_instancesChanged

      _instancesChanged: (instances) =>
        @setState have_dates: @props.eventInstances.count() > 0

      # Convert the incoming schedule data props to proper internal state
      #
      # This sometimes means reversing the "macros" from it
      _calculateScheduleState: (data) =>
        weekdays = (Number(i) for i in data.weekdays.split(/,/))

        state =
          weekdays: weekdays
          interval: data.interval
          send_time: Number(data.send_time)
          start_date: data.start_date
          schedule_type: 'automatic'

        switch data.recurrence_type
          when 'weekly'
            if _.isEqual weekdays, [2,4]
              state.repeat_type = 'weekdays_tth'
            else if _.isEqual weekdays, [1,3,5]
              state.repeat_type = 'weekdays_mwf'
            else if _.isEqual weekdays, [1,2,3,4,5]
              state.repeat_type = 'weekdays'
            else
              state.repeat_type = 'weekly'
          when 'monthly'
            state.repeat_type = 'monthly_day_of_month'
          when 'monthly_day'
            state.repeat_type = 'monthly_day_of_week'
          when 'manual'
            state.repeat_type = 'manual'
            state.schedule_type = 'manual'
          else
            state.repeat_type = data.recurrence_type

        if data.recurrence_count > 0
          state.recurrence_end_type = 'count'
          state.recurrence_end_count = data.recurrence_count
        else if data.end_date
          state.recurrence_end_type = 'date'
          state.recurrence_end_date = data.end_date
        else
          state.recurrence_end_type = 'none'


        state

      # Handler for input changes
      _scheduleTypeChanged: (event) =>
        state_updates = {}

        switch event.target.value
          when 'manual'
            state_updates.schedule_type = 'manual'
          when 'automatic'
            state_updates.schedule_type = 'automatic'
            state_updates.repeat_type = 'once' if this.state.repeat_type == 'manual'
          else
            console.error "unknown schedule type: #{event.target.value}"

        this.setState state_updates

      _inputChanged: (event, value) =>
        switch event.target.name
          when 'start_date'
            if value == 'error' || value == 'blank'
              @setState start_date_invalid: true, start_date: null, @validateState
            else
              @setState start_date_invalid: false, start_date: value, @validateState

          when 'send_time'
            @setState send_time: event.target.value, @validateState

          when 'repeats'
            @setState repeat_type: event.target.value, @validateState

          when 'interval'
            @setState interval: event.target.value, @validateState

          when 'new_instance_recurrence_day'
            new_weekdays = @state.weekdays.splice(0)
            weekday = Number(event.target.value)

            if value
              # Chosen
              new_weekdays.push weekday unless new_weekdays.indexOf(weekday) > -1
            else
              # Removed
              index = new_weekdays.indexOf(weekday)
              if index > -1
                new_weekdays.splice index, 1

            @setState weekdays: new_weekdays.sort(), @validateState

          when 'month_repeat_on'
            @setState month_repeat_on: event.target.value, @validateState

          when 'recurrence_end_type'
            @setState recurrence_end_type: event.target.value, @validateState

          when 'recurrence_end_date'
            if value == 'error'
              @setState recurrence_end_date_invalid: true, recurrence_end_date: null, @validateState
            else if value == 'blank'
              @setState recurrence_end_date_invalid: true, recurrence_end_date: null, @validateState
            else
              @setState recurrence_end_date_invalid: false, recurrence_end_date: value, @validateState

          when 'recurrence_end_count'
            if Number(event.target.value) > 0
              @setState recurrence_end_count: event.target.value, recurrence_end_count_invalid: false, @validateState
            else
              @setState recurrence_end_count: event.target.value, recurrence_end_count_invalid: true, @validateState

          when 'new_instance_remove_future_dates'
            @setState remove_future_instances: value, @validateState

        @props.onInputChange?(event)
        event.stopPropagation()

      # Handler for clicking of add button, this commits the changes to the instance list
      addInstances: (event) =>
        settings = @getRecurrenceSettings()
        @props.eventInstances.addRecurring(settings)
        @props.onInstancesAdded?(event)

      # Validate the state and set the global validation flag appropriately
      validateState: =>
        # Form validation checks
        start_date_invalid = this.state.start_date_invalid || !this.state.start_date
        recurrence_end_count_invalid = this.state.recurrence_end_count_invalid || !this.state.recurrence_end_count
        recurrence_end_date_invalid = this.state.recurrence_end_date_invalid || !this.state.recurrence_end_date

        valid_start_date = !this.state.start_date_invalid && this.state.start_date
        valid_start_time = !this.state.start_time_invalid
        valid_end_time = !this.state.end_time_invalid
        valid_recurrence_end = this.state.repeat_type == 'once' || this.state.repeat_type == 'manual' ||
          (this.state.recurrence_end_type == 'date' && !this.state.recurrence_end_date_invalid && this.state.recurrence_end_date) ||
          (this.state.recurrence_end_type == 'count' && !this.state.recurrence_end_count_invalid && this.state.recurrence_end_count)

        @setState
          valid_entry: valid_start_date && valid_start_time && valid_end_time && valid_recurrence_end
          start_date_invalid: start_date_invalid


      render: ->
        my = this

        # Generate some of the option selections for large, programmtic lists

        # ...count list
        interval_unit = switch this.state.repeat_type
          when 'daily'
            'day'
          when 'weekdays', 'weekdays_mwf', 'weekdays_tth', 'weekly'
            'week'
          when 'monthly_day_of_week', 'monthly_day_of_month'
            'month'
          when 'yearly'
            'year'
          else
            ''

        interval_options = (`<option key={i} value={i}>{i} {interval_unit}{i != 1 ? 's' : ''}</option>` for i in [1..30])

        # ...weekday option checkboxes
        weekday_options = (`<td key={day}>
                              <span className="day">{lbl}</span>
                              <Checkbox value={day}
                                        checked={my.state.weekdays.indexOf(day) > -1}
                                        className="recur_day"
                                        data-instance="recurrence-day"
                                        onChange={my._inputChanged}
                                        name="new_instance_recurrence_day" />
                            </td>` for [day, lbl] in [
          [0, 'Sun'],
          [1, 'Mon'],
          [2, 'Tue'],
          [3, 'Wed'],
          [4, 'Thu'],
          [5, 'Fri'],
          [6, 'Sat']
        ])

        send_time_options = (`<option key={i} value={i}>{moment().set({hours: i}).format('ha')}</option>` for i in [0..23])

        # Calculate visibility options
        is_recurring = this.state.repeat_type != 'once'
        summary_text = this.getRecurranceSummary()

        # Active date text
        start = if this.state.start_date then moment(this.state.start_date) else moment()

        if start.date() == start.daysInMonth()
          day_of_month_text = 'last'
        else
          day_of_month_text = start.format('Do')

        week_number = start.monthWeekByDay()
        if week_number == 4
          day_of_week_text = "last #{start.format('dddd')}"
        else
          day_of_week_text = ['first', 'second', 'third', 'fourth'][week_number] + " " + start.format('dddd')

        recurrence_settings = this.getRecurrenceSettings()

        schedule_form = if this.state.schedule_type == 'automatic'
          `<div>
            <DateInputRow label="Start Date"
                          name="start_date"
                          placeholder="'Tomorrow' or 'Next Friday' or '4/10'"
                          initialValue={this.state.start_date}
                          onChange={this._inputChanged}
                          showPreview={false}
                          showError={false}
                          required={true} />

            <FormRow title="Send Time">
              <select name="send_time"
                      ref="send_time"
                      value={this.state.send_time}
                      onChange={this._inputChanged}>
                {send_time_options}
              </select>
            </FormRow>

            <FormRow title="Repeating">
              <select ref="repeats"
                      value={this.state.repeat_type}
                      onChange={this._inputChanged}
                      name="repeats">
                <option value="once">Never</option>
                <option value="daily">Every day</option>
                <option value="weekdays">Every weekday</option>
                <option value="weekdays_mwf">Every Mon, Wed, Fri</option>
                <option value="weekdays_tth">Every Tue, Thu</option>
                <option value="weekly">Every week</option>
                <option value="monthly_day_of_month">The {day_of_month_text} day</option>
                <option value="monthly_day_of_week">The {day_of_week_text}</option>
                <option value="yearly">Every year</option>
              </select>
            </FormRow>

            {['daily', 'weekly', 'monthly_day_of_month', 'monthly_day_of_week', 'yearly'].indexOf(this.state.repeat_type) > -1 &&
              <FormRow title="Every">
                <select ref="interval"
                        value={this.state.interval}
                        onChange={this._inputChanged}
                        name="interval">
                  {interval_options}
                </select>
              </FormRow>}

            {this.state.repeat_type == 'weekly' && <FormRow title="Repeat on"
                     className="week_table_wrapper"
                     data-instance="recurrence-day-wrapper">
              <div className="week_table">
                <table border="0">
                  <tbody>
                    <tr>
                      {weekday_options}
                    </tr>
                  </tbody>
                </table>
              </div>
            </FormRow>}

            {is_recurring && <FormRow title="Repeating until" className="end-date-range">
              <select className="end-date-range-input"
                      name="recurrence_end_type"
                      value={this.state.recurrence_end_type}
                      onChange={this._inputChanged}>
                <option value="none">Stopped</option>
                <option value="date">A specific date has passed</option>
                <option value="count">A number of times have passed</option>
              </select>
            </FormRow>}

            {is_recurring && this.state.recurrence_end_type == 'date' &&
              <DateInputRow label=""
                            name="recurrence_end_date"
                            onChange={this._inputChanged}
                            placeholder="tomorrow"
                            initialValue={this.state.recurrence_end_date}
                            showPreview={false}
                            showError={false}
                            type="date"/>}

            {is_recurring && this.state.recurrence_end_type == 'count' &&
              <FormRow title="">
                <input type="text"
                       name="recurrence_end_count"
                       value={this.state.recurrence_end_count}
                       onChange={this._inputChanged}
                       placeholder="12" />
              </FormRow>}

            {summary_text && <FormRow title="Summary">
              <div className="schedule-summary">{summary_text}</div>
            </FormRow>}
          </div>`

        else
          null

        `//`# Main UI
        `<div>
          <FormInputGroup>
            <FormRow title="Delivery">
              <select name="schedule_type"
                      ref="schedule_type"
                      value={this.state.schedule_type}
                      onChange={this._scheduleTypeChanged}>
                <option value="manual">Send manually</option>
                <option value="automatic">Send on a schedule</option>
              </select>
            </FormRow>

            {schedule_form}

          </FormInputGroup>

          {/* Fields to communicate the schedule back to the server */}
          <input type="hidden" name="bulletin_campaign[recurrence_type]" value={recurrence_settings.type} />
          <input type="hidden" name="bulletin_campaign[start_date]" value={recurrence_settings.start_date ? moment(recurrence_settings.start_date).format('YYYY-MM-DD') : ""} />
          <input type="hidden" name="bulletin_campaign[end_date]" value={recurrence_settings.recurrence_end_date ? moment(recurrence_settings.recurrence_end_date).format('YYYY-MM-DD') : ""} />
          <input type="hidden" name="bulletin_campaign[interval]" value={recurrence_settings.interval} />
          <input type="hidden" name="bulletin_campaign[recurrence_count]" value={recurrence_settings.recurrence_end_count || ''} />
          <input type="hidden" name="bulletin_campaign[weekdays]" value={recurrence_settings.weekdays.join(',')} />
          <input type="hidden" name="bulletin_campaign[send_time]" value={recurrence_settings.send_time} />

        </div>`

      # Generate summary text
      #
      # DAILY/WEEKLY: Every [DOW1, DOW2 | Weekday | Weekend | Day]
      # MONTHLY: Every Nth day | Every third DOW
      # YEARLY: Every X years
      #
      # ending with:
      #  ; X times
      #  ; until Date
      getRecurranceSummary: =>
        settings = this.getRecurrenceSettings()

        # Return error message if part of the form is invalid
        return "Invalid Start Date" if this.state.start_date_invalid

        # Show errors or hints for recurrence end
        if this.state.repeat_type != 'once'
          # Recurrence end date
          if this.state.recurrence_end_type == 'date'
            if this.state.recurrence_end_date_invalid
              return "Invalid Recurrence End Date"
            else if !this.state.recurrence_end_date
              return "Enter a recurrence end date above"

          # Recurrence end count
          if this.state.recurrence_end_type == 'count'
            if this.state.recurrence_end_count_invalid
              return "Invalid Recurrence End Count"
            else if !this.state.recurrence_end_count
              return "Enter a recurrence end count above"

        # Otherwise, build up the instance summary text
        summary_text_date = []
        if settings.start_date
          summary_text_date.push moment(settings.start_date).format('ddd, MMM D, YYYY') if settings.start_date
          summary_text_date.push moment().set(hours: settings.send_time).format(', ha') if settings.send_time?

        summary_text_date = summary_text_date.join('')

        if this.state.repeat_type != 'once'
          day_labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

          summary_text = "repeating every "
          summary_text += numberAsWord(settings.interval) + " " if settings.interval > 1

          switch settings.type
            when 'daily'
              interval_label = pluralize(settings.interval, 'day')
            when 'weekly'
              interval_label = pluralize(settings.interval, 'week')

              if _.isEqual settings.weekdays, [0, 1, 2, 3, 4, 5, 6]
                interval_label += ' on all days'
              else if _.isEqual settings.weekdays, [0, 6]
                interval_label += ' on weekends'
              else if _.isEqual settings.weekdays, [1, 2, 3, 4, 5]
                interval_label += ' on weekdays'
              else
                interval_label += ' on ' + (day_labels[day] for day in settings.weekdays).join(', ')
            when 'monthly', 'monthly_day'
              interval_label = pluralize(settings.interval, 'month')

              # Show the date it recurs on (either date or weekday)
              if settings.start_date
                start_date = moment(settings.start_date)

                if settings.repeat_on == 'day_of_month'
                  if start_date.date() == start_date.daysInMonth()
                    interval_label += " on the last day"
                  else
                    interval_label += " on the #{start_date.format('Do')}"
                else if settings.repeat_on == 'day_of_week'
                  week_number = start_date.monthWeekByDay()
                  if week_number == 4
                    week_number = 'last'
                  else
                    week_number = ['first', 'second', 'third', 'fourth'][week_number]

                  interval_label += " on the #{week_number} #{start_date.format('dddd')}"

            when 'yearly'
              interval_label = pluralize(settings.interval, 'year')

          summary_text += interval_label

          # Range end date
          if this.state.recurrence_end_type == 'date' && this.state.recurrence_end_date
            summary_text += " until "
            summary_text += moment(this.state.recurrence_end_date).format('MMM D, YYYY')
          else if this.state.recurrence_end_type == 'count' && this.state.recurrence_end_count > 0
            summary_text += ", " + this.state.recurrence_end_count + " times"
        else
          summary_text = null

        result = _.compact([summary_text_date, summary_text]).join(', ')

        # Hint if we aren't showing an error or a summary
        result = 'Enter a start date above' unless result

        result

      # Return the current recurrence settings based on the user's selections
      #
      # This normalizes the weekday dropdown choices with the selected weekdays
      getRecurrenceSettings: =>
        result = {}

        weekdays = this.state.weekdays
        result.interval = this.state.interval

        switch this.state.schedule_type
          when 'manual'
            result.type = 'manual'
            weekdays = [0, 1, 2, 3, 4, 5, 6]
            result.start_date = moment().format('YYYY-MM-DD')
            result.send_time = moment().get('hours')

          when 'automatic'
            switch this.state.repeat_type
              when 'once'
                result.type = 'once'
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'daily'
                result.type = 'daily'
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'weekly'
                result.type = 'weekly'
              when 'monthly'
                result.type = 'monthly'
                result.repeat_on = this.state.month_repeat_on
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'monthly_day_of_month'
                result.type = 'monthly'
                result.repeat_on = 'day_of_month'
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'monthly_day_of_week'
                result.type = 'monthly_day'
                result.repeat_on = 'day_of_week'
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'yearly'
                result.type = 'yearly'
                weekdays = [0, 1, 2, 3, 4, 5, 6]
              when 'weekdays'
                result.type = 'weekly'
                result.interval = 1
                weekdays = [1, 2, 3, 4, 5]
              when 'weekdays_mwf'
                result.type = 'weekly'
                result.interval = 1
                weekdays = [1, 3, 5]
              when 'weekdays_tth'
                result.type = 'weekly'
                result.interval = 1
                weekdays = [2, 4]

            result.start_date = this.state.start_date
            result.send_time = this.state.send_time

        result.weekdays = weekdays.sort()

        switch this.state.recurrence_end_type
          when 'count'
            result.recurrence_end_count = this.state.recurrence_end_count
            result.recurrence_end_date = null
          when 'date'
            result.recurrence_end_count = null
            result.recurrence_end_date = this.state.recurrence_end_date
          else
            result.recurrence_end_count = null
            result.recurrence_end_date = null


        result