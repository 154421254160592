modulejs.define 'registration/components/checkout/attendee_details',
  ['underscore', 'react', 'object-path-immutable', 'prop-types', 'isemail',
   'slzr/react/form_input_group', 'slzr/react/form_row', 'slzr/react/form_header', 'registration/utilities',
   'registration/components/checkout/attendee_detail_form', 'registration/components/checkout/reservation_timer',
   'registration/components/checkout/cart'],
  (_, React, immutable, PropTypes, isemail,
   FormInputGroup, FormRow, FormHeader, Utilities,
   AttendeeDetailForm, ReservationTimer,
   Cart) ->

    class AttendeeDetails extends React.Component
      constructor: (props) ->
        super props

        this.state =
          # Attendee details and survey responses
          answers: props.attendees

      # Keep the answers in sync with the props, if they change
      UNSAFE_componentWillReceiveProps: (nextProps) ->
        if nextProps.attendees != this.props.attendees
          this.setState answers: nextProps.attendees if nextProps.attendees

      # Callback when an attendee's form is changed.
      #
      # ticket_class_id: ID of ticket class
      # attendee_number: Offset of attendee (i.e. "Default #2"), but zero based
      # name: Form field name
      # index: Form field index
      # value: New value
      onAttendeeChanged: (ticket_class_id, attendee_number, name, index, value) ->
        update_path = [String(ticket_class_id), attendee_number - 1, name]
        update_path.push(String(index)) if index?

        this.setState answers: immutable.set(this.state.answers, update_path, value)

      # Handle a form submission
      #
      # Calls the onSubmit callback with the form data provided by the user
      #
      # The answers are structured as a hash with the key being the ticket class id, and the value being
      # an array of responses for each attendee in that ticket class
      #
      # Each response is a hash of "name" or "email", and "answers", which is the response to questions by ID.
      onSubmitForm: (event) =>
        event.preventDefault()
        this.props.onSubmit?(attendees: this.state.answers)

      # User requested navigation back to the quantity form
      onBack: (event) =>
        event.preventDefault()
        this.props.onGoBack?(attendees: this.state.answers)

      # User requested to cancel checkout
      onCancel: (event) =>
        event.preventDefault()
        this.props.onCancel?()

      renderAttendeeRows: ->
        my = this
        for item in this.props.cart.items
          for i in [1..item.quantity]
            answers = my.state.answers?[String(item.ticket_class_id)]?[i - 1] || my.props.attendees?[item.ticket_class_id]?[i - 1] || {}
            errors = my.props.attendee_errors?[String(item.ticket_class_id)]?[i - 1]

            `<FormInputGroup className="attendee-group-wrap" key={item.ticket_class_id + '-' + i}>
              <legend className="em-attendee_section_title">{item.name} Attendee #{i}</legend>

              <AttendeeDetailForm item={item}
                                  index={i}
                                  questions={my.props.questions}
                                  answers={answers}
                                  errors={errors}
                                  key={item.ticket_class_id + '-' + i}
                                  onChange={my.onAttendeeChanged.bind(my, item.ticket_class_id, i)}
              />
            </FormInputGroup>`

      render: ->
        `<div className="widget-content">
          <ReservationTimer now={this.props.now}
                              reservationEnds={this.props.reservation_ends_at}
                              browserTimeOffset={this.props.browser_time_offset} />


            <form className="car_form form-sidebar">
              <span className="em-section_register_title">Attendee Information</span>
              {this.renderAttendeeRows()}

              <span className="em-section_register_title">Purchase Summary</span>
              <Cart items={this.props.cart.items}
                    fee={this.props.cart.fee}
                    total={this.props.cart.total}
                    currency={this.props.currency} />




              {this.props.error && <p className="error_marker">There was an error processing your request. Please try again</p>}

              <div className="action_button">
                <a href="#" className="cancel_link em-link" onClick={this.onBack}>Back</a>
                <a href="#" className="cancel_link em-link" onClick={this.onCancel}>Cancel</a>
                <input type="submit"
                       className="em-button em-primary"
                       onClick={this.onSubmitForm}
                       disabled={this.props.loading}
                       value={this.props.loading ? "Processing..." : "Continue"} />

              </div>
            </form>
        </div>`
