modulejs.define 'registration/components/checkout/attendee_detail_form',
  ['underscore', 'react', 'prop-types', 'object-path-immutable', 'isemail', 'classnames'
   'slzr/react/form_row', 'registration/utilities'],
  (_, React, PropTypes, immutable, isemail, classNames,
   FormRow, Utilities) ->

    class AttendeeDetailForm extends React.Component
      @propTypes:
        # List of additional questions
        questions: PropTypes.array
        # List of answers to the above questions, in order
        answers: PropTypes.object
        errors: PropTypes.object
        name: PropTypes.string
        email: PropTypes.string
        onChange: PropTypes.func

      @defaultProps:
        questions: []
        answers: {}
        errors: {}

      constructor: (props) ->
        super props

        this.state =
          # Value is non-null when the input field named "key" has an error (blank but required, or invalid format)
          errors: {}
          # Each value is true when the input field named "key" has been modified
          dirty: {}

      # When we receive new props, update any error states
      UNSAFE_componentWillReceiveProps: (nextProps) ->
        if nextProps.errors != this.props.errors
          # Replace error state with a copy of what we were given
          this.setState errors: _.extend({}, nextProps.errors)

          # And mark any fields as dirty that have errors
          new_dirty = this.state.dirty

          for key of nextProps.errors
            new_dirty = immutable.set(new_dirty, String(key), true)

          this.setState dirty: new_dirty

        else if nextProps.answers != this.props.answers
          # Refresh our errors
          new_errors = {}

          # Validate presence of name and email
          for key in ['name', 'email']
            new_errors[key] = 'required' if this.state.dirty[key] && Utilities.isBlank(nextProps.answers?[key])

          # Validate format of email
          new_errors['email'] = 'invalid_format' if this.state.dirty['email'] && !isemail.validate(nextProps.answers?['email'])

          for question in nextProps.questions
            continue unless question.required
            new_errors[question.id] = 'required' if this.state.dirty[String(question.id)] && Utilities.isBlank(nextProps.answers?.answers?[question.id])

          this.setState errors: new_errors

      onChange: (event) =>
        this.setState dirty: immutable.set(this.state.dirty, event.target.name, true)

        switch event.target.name
          when 'name', 'email'
            name = event.target.name
            index = null
          else
            name = 'answers'
            index = Number(event.target.name)

        this.props.onChange?(name, index, event.target.value)

      renderRequired: =>
        `<span className='required'>REQUIRED</span>`

      renderTextFieldElement: (classes, error_message, question, value, describedby, form_key) =>
        `<div className={error_message ? "em-input-floating em-error" : "em-input-floating"}>
          <input key={question.id}
                 id={form_key}
                 aria-describedby={describedby}
                 type="text"
                 className={classes}
                 onChange={this.onChange}
                 name={question.id}
                 value={value}
                 required={question.required}
                 placeholder={question.question}/>
          <label htmlFor={form_key}>
            {question.question}
            {question.required && this.renderRequired()}
          </label>
        </div>`

      renderSelectElement: (classes, error_message, question, value, describedby, form_key) =>
        choices = _.map(question.choices, (choice, index) -> `<option key={index} value={choice}>{choice}</option>`)

        klasses = classNames 'em-select-floating',
                             'em-error': !!error_message,
                             'em-select-floating--placeholder': value == "" || value == undefined

        `<div className={klasses}>
          <label htmlFor={form_key}>
            {question.question}
            {question.required && this.renderRequired()}
          </label>
          <select name={question.id}
                  id={form_key}
                  aria-describedby={describedby}
                  className={classes}
                  onChange={this.onChange}
                  value={value}
                  required={question.required}>
            <option key="0" value="" disabled={question.required} selected={true}></option>
            {choices}
          </select>
          <i className="fas fa-sort-down"></i>
        </div>`

      renderQuestionRows: (form_key) ->
        for index, question of this.props.questions
          error_message = if this.state.errors[question.id]
            "You must answer #{question.question}"

          classes = "error_marker" if this.state.errors[question.id]
          value = this.props.answers?.answers?[question.id]
          describedby = "#{form_key}-attendee-question-#{question.id}-error"
          question_form_key = "#{form_key}-attendee-question-#{question.id}"

          response_element =  if question.response_type == "dropdown"
                                this.renderSelectElement(classes, error_message, question, value, describedby, question_form_key)
                              else
                                this.renderTextFieldElement(classes, error_message, question, value, describedby, question_form_key)

          ` <div className="em-input_container" key={index}>
              {response_element}
              {error_message && <span className="error_marker" id={describedby}>{error_message}</span>}
            </div>`

      render: ->
        return null unless this.props.item

        header_text = "#{this.props.item.name} Attendee ##{this.props.index}"
        form_key = "#{this.props.item.ticket_class_id}-#{this.props.index}"
        
        `<div key={this.props.item.ticket_class_id + '-' + this.props.index}>
          <div className="em-attendee_info">
            <div className="em-input_container ">
              <div className={this.state.errors.name == 'required' ? "em-input-floating error" : "em-input-floating"}>
                <input type="text"
                       id={form_key + "-attendee-name"}
                       aria-describedby={form_key + "-attendee-name-error"}
                       name="name"
                       autocomplete="name"
                       required="required"
                       onChange={this.onChange}
                       value={this.props.answers['name']}
                       placeholder="Full Name REQUIRED"/>
                <label htmlFor={form_key + "-attendee-name"}>
                  Full Name {this.renderRequired()}
                </label>
              </div>
              {this.state.errors.name == 'required' &&
                <span className="error_marker" role="alert" id={form_key + '-attendee-name-error'}>The attendee name is required</span>}
            </div>
            <div className="em-input_container">
              <div
                className={(this.state.errors.email == 'invalid_format' || this.state.errors.email == 'required') ? "em-input-floating em-error" : "em-input-floating"}>
                <input type="text"
                       id={form_key + "-attendee-email"}
                       aria-describedby={form_key + "-attendee-email-error"}
                       name="email"
                       required="required"
                       autocomplete="email"
                       onChange={this.onChange}
                       value={this.props.answers['email']}
                       placeholder="Email Address REQUIRED"/>
                <label htmlFor={form_key + "-attendee-email"}>
                  Email Address {this.renderRequired()}
                </label>
              </div>
              {this.state.errors.email == 'required' &&
                <span className="error_marker" role="alert" id={form_key + "-attendee-email-error"}>The attendee email is required</span>}
              {this.state.errors.email == 'invalid_format' &&
                <span className="error_marker" role="alert" id={form_key + "-attendee-email-error"}>That is not a valid email</span>}
            </div>
          </div>


          {this.renderQuestionRows(form_key)}
        </div>`
